
import { Component, Vue, Prop } from "vue-property-decorator";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
const teacher1 = require('./../../../assets/imgs/teacher1.png'),
        teacher2 = require('./../../../assets/imgs/teacher2.png'),
        teacher3 = require('./../../../assets/imgs/teacher3.png'),
        teacher4 = require('./../../../assets/imgs/teacher4.png'),
        teacher5 = require('./../../../assets/imgs/teacher5.png'),
        teacher6 = require('./../../../assets/imgs/teacher6.png'),
        teacher7 = require('./../../../assets/imgs/teacher7.png'),
        teacher8 = require('./../../../assets/imgs/teacher8.png'),
        teacher9 = require('./../../../assets/imgs/teacher9.png'),
        teacher10 = require('./../../../assets/imgs/teacher10.png')
@Component({
  name: "TeacherSetions",
})

export default class TeacherSetions extends Vue {
  @Prop() lang!: string;

  private onSwiper(swiper: any): void {
    console.log(swiper);
  }
  private onSlideChange(): void {
    console.log("swiperchange");
  }
  private teacherInfo = [
    {
      name: "ZhangTao",
      gep: "GEP（P2-P3）",
      photo: teacher1,
      info: "Teacher Zhang is currently the head of P2-P3 and GEP Mathematics. In 2023, 75% of his P3 students progressed to the second round of the GEP selection, and 25% successfully entered the GEP class.",
    },
    {
      name: "Cui Jia ming",
      gep: "Primary Olympiad Mathematics（P4-P6）",
      photo:teacher2,
      info: "Teacher Cui is the lead teacher for upper primary levels. From 2023-2024, he has led students to win over 20 NMOS gold medals, 8 APMOPS platinum awards, and nearly half of his students can advance to the second round of the RMO. ",
    },
    {
      name: "Smith",
      gep: "Primary Olympiad Mathematics（P4-P6）",
      photo: teacher3,
      info: "Teacher Smith has 3+ years of experience in teaching Mathematics. He is currently the lead teacher of the P5 & P6 Math Olympiad and is committed to assisting students in excelling in NMOS, RMO and APMOPS.",
    },
    {
      name: "Jeremiah Ang",
      gep: "Primary Olympiad Mathematics（P4-P6）",
      photo: teacher5,
      info: "Teacher Ang currently serves as the head of teaching for P4. He has 1+ years of private tutoring experience, which helps him identify the weaknesses of students and develop effective methods to improve on those weak areas.",
    },
    {
      name: "Meng Tiantian",
      gep: "PSLE (P5-P6)",
      photo: teacher6,
      info: "Teacher Meng has been teaching for more than 7 years and is currently the vice principal of Kangaroo Study. In Spring Cup (with difficulty higher than RMO), 61 students of her received first, second, and third prizes. She currently leads the development of the PSLE team, focusing on enhancing student performance to achieve AL1 in PSLE.",
    },
    {
      name: "Ma Zhangchi",
      gep: "PSLE (P5-P6)",
      photo: teacher7,
      info: "Coming from a family of math teachers, teacher Ma gained teaching experience as a teaching assistant during college. He currently teaches PSLE Math, prioritizing clarity and encouraging active engagement.",
    },
    {
      name: "Ji Hany",
      gep: "PSLE (P5-P6)",
      photo: teacher8,
      info: "Teacher Ji started studying for math competitions since primary school and discovered his passion for teaching while volunteering to teach young children basic math and coding skills. He is committed to helping students excel in the PSLE Math exam.",
    },
    {
      name: "Gao Xuming",
      gep: "Secondary (S1-S2)",
      photo: teacher9,
      info: "Teacher Gao is well-versed in the secondary Olympiad mathematics system. In 2024, 13 of his students advanced to the second round of the SMO Junior Group, 6 won Gold Medals, and 3 advanced to the Junior Training Team.",
    },
    {
      name: "Zhang Wenlong",
      gep: "Secondary (S1-S2)",
      photo: teacher10,
      info: "Teacher Zhang gained extensive teaching experience as a teaching assistant throughout the university years. He advocates for guided teaching in secondary school math to foster independent thinking and problem-solving skills.",
    }
  ];
  private teachSwiper: any = null
  mounted() {
    this.teachSwiper = new Swiper(".mySwiper", {
      slidesPerView: "auto",
      loop: !0,
      lazy: {
        loadPrevNext: !0
      },
      preloadImages: !1,
      loopAdditionalSlides: 9,
      loopedSlides: 5,
      autoplay: {
        delay: 0, //自动切换的时间间隔
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      speed: 6e3,
      spaceBetween: 30,
      freeMode: !0,

      interval: 1500,
      duration: 1500,
      navigation: {
        nextEl: null,
        prevEl: null,
      },


    });
  }
  beforeDestroy(){
    this.teachSwiper = null
  }
}
